<template>
  <div id="ProducerPublicOptin">
    <QsCard :qlik-ids="['MryLz']" />
    <QsCard :qlik-ids="['PUcRWUM']" />
    <QsCard :qlik-ids="['eNunpf']" style="grid-column: 3 / span 2" />

    <QsCard :qlik-ids="['FLrmhD']" />
    <div
      class="optin-config-container"
      @click="$mixpanel.track('Click opt-in config button')"
    >
      <GoToDataModificationButton title="Configurez vos opt-ins" />
      <fa :icon="['fal', 'long-arrow-right']" size="6x" />
    </div>
    <QsCard :qlik-ids="['STrqqk']" style="grid-column: 3 / span 2" />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";
import GoToDataModificationButton from "@/components/GoToDataModificationButton.vue";

export default { components: { QsCard, GoToDataModificationButton } };
</script>

<style scoped>
#ProducerPublicOptin {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 300px 400px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}

.optin-config-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
