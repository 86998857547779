<template>
  <div class="button-container">
    <el-tooltip :content="tooltip" placement="bottom-end">
      <el-button type="primary" @click="goToGsheets">
        <b>{{ title }}</b>
        <fa :icon="['far', 'external-link-square']" size="lg" class="ml-3" />
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

export default {
  name: "GoToDataModificationButton",
  props: {
    title: { type: String, default: "Configuration" },
    tooltip: { type: String, default: "Lien vers la page de configuration" }
  },
  computed: mapState(["app"]),
  methods: {
    goToGsheets() {
      const { qDocName } = this.app;
      window.open(`https://redirect.delight-data.com/v1/?app_name=${qDocName}`);
    }
  }
};
</script>

<style scoped>
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
